import React from 'react'
import { useInView } from 'react-intersection-observer'

import thousandP from '../assets/img/WebText_1000.png'
import imgBB from '../assets/img/WebText_Battle_1.png'
import imgBB2 from '../assets/img/WebText_Battle_2.png'
import prinnyDead from '../assets/img/prinny-dead.png'
import prinnyBrave from '../assets/img/prinny-brave.png'
import imgSpecial from '../assets/img/WebText_SpecialMove_Prinny2_1.png'
import imgSpecial2 from '../assets/img/WebText_SpecialMove_Prinny2_2.png'

import p1000 from '../assets/img/1000prinny.gif'

import asagiHappy from '../assets/img/asagi-happy.png'
import flonneHappy from '../assets/img/flonne-happy.png'

import imgModes from '../assets/img/WebText_Difficulty_Baby.png'
import charBG from '../assets/img/char-bg.png'
import { MotionChar } from './MotionChar'

export const System = () => {
    const thresh = .4
    const animateIn = 'fadeIn '
    const animateOut = "fadeOut"
    const [items, setItems] = React.useState([])
    const [braves, setBraves] = React.useState([])
    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: thresh,
      })
    const [ref2, inView2, entry2] = useInView({
    /* Optional options */
    threshold: thresh,
    })
    const [ref3, inView3, entry3] = useInView({
    /* Optional options */
    threshold: thresh,
    })
    const [ref4, inView4, entry4] = useInView({
    /* Optional options */
    threshold: thresh,
    })
    const [bravies, braviesInView, braviesEntry] = useInView({
        /* Optional options */
        threshold: .7,
        })

    const data = [
        {
            img: thousandP,
            title: `1000 PRINNIES = 1000 CHANCES`,
            body: <p className="system-p" style={{textAlign:'center'}}>
                What the Prinny legion lacks in strength, intelligence, charisma, and Class Levels, they make up for with sheer numbers, dood! Every time a Prinny goes down, a new one hops in to don the scarf and continue the stage, effectively giving them 1000 lives before it’s Game Over. Also, special attacks such as Prinnykaze burn through your extra Prinnies, so don’t get too reckless, dood!

            </p>
        },
        {
            img:imgBB,
            title: `BATTLE BASICS`,
            body: <p className="system-p">
                Unleash blistering combos and cut your foes down to size with not one but <span style={{color:'#fad100', fontSize:'1em'}}><strong>TWO</strong></span> deadly Prinny knives! If you don’t want to get your flippers dirty, Lift & Throw bombs to strike from a distance, or hop into fully-armed vehicles and go cruisin’ for a bruisin’, dood!
            </p>
        },
        {
            img:imgSpecial,
            title: `SPECIAL MOVES`,
            body: <p className='system-p' style={{textAlign:'center'}}>
                Dish out the pain in <span style={{color:'#fad100'}}><i>Prinny®1: Can I Really Be the Hero?</i></span> with slick moves like Hip 
                Pound and Spin. Also, use powerful attacks like Prinny Raid to eradicate your enemies!
                But wait, there’s more! 
                <br/>
                <br/>
                Dial up your rampage to “11” in <span style={{color:'#fad100'}}><i>Prinny®2: Dawn of Operation Panties, Dood! </i></span> with Husky Hip Pound, Nether Yams, and the patented Break Mode, which lets you unleash devastating special moves such Prinny Cyclone and Prinnykaze!
            </p>
        },
        {
            img:'',
            title: `DIFFICULTY MODES`,
            body:   <p className='system-p' style={{textAlign:'center'}}>
                        Shape your game experience with adjustable difficulty settings, dood!
                        <br/>
                        <br/>
                        <span style={{color:'#f4b605', textAlign:'left'}}>Standard Mode</span> provides death-defying red scarves, or show off your Prinny skills with <br/><span style={{color:'#f4b605'}}>Hell’s Finest Mode</span> the scarves are off and every Prinny can (and will) die after one hit!
                        
                        
                        <br/>
                        <br/>
                        For those looking for a less challenging, more relaxed playthrough, <span style={{color:'lightlue'}}><i>Prinny®2: Dawn of Operation Panties</i></span> offers: <br/> <span style={{color:'#f4b605'}}>Baby Mode</span>
             , which gives you three...er, <span style={{color:'lightblue'}}><i>special</i></span> scarves to save you from an early demise, plus blocks on each stage to help you overcome obstacles. 
             Hey, even the best Prinnies need a break every now and then, dood!</p>,

            // body:   <div style={{textAlign:'center'}}>
            // <div>Shape your game experience with adjustable difficulty settings, dood!</div>
            // <div>
            // Play through <span style={{color:'#f4b605'}}>Standard Mode</span>, which provides death-defying red scarves, or show off your Prinny skills with <span style={{color:'blue'}}>Hell’s Finest Mode</span>, where the scarves are off and every Prinny can (and will) die after one hit!
            // </div>


            // <br/>For those looking for a less challenging, more relaxed playthrough, Prinny®2: Dawn of Operation Panties offers <span style={{color:'blue'}}>Baby Mode</span>
            // , which gives you three...er, special scarves to save you from an early demise, plus blocks on each stage to help you overcome obstacles. 
            // Hey, even the best Prinnies need a break every now and then, dood!`</div>
        }
    ]
    

    const bravePrinnies = (len) =>
    {
        let arr = []
        let timeOffset = 200
        let posOffset = 16
        let screenWidth = window.innerWidth
        for(let i=0; i<len; i++)
        {

            arr.push(
               
                    <MotionChar key={i} loop = {false} images={[prinnyBrave, prinnyDead]} times={[5000 + timeOffset*i, 5300 - timeOffset*i]} 
                    style={{position:'absolute', transform:`translateX(${posOffset*i}px)`}}
                    />
               
            )
           
        }

        setBraves(arr)
    }

    


    React.useEffect(()=>
    {
        if(braviesInView)
        {
                    bravePrinnies(6)

        }
        else{
            let tim = setTimeout(()=>
            {
                // bravePrinnies(0)
            },1000)
            
        }

    },[braviesInView])
    return (
        <section id="system" className={`flex col`} style={{overflow:'hidden'}}>

            <h1 className='animated pulse infinite'>SYSTEM</h1>
            <div ref={ref} className={`system-item flex col`}>
                <h3 className={`system-h3 shadow ${inView?`animated ${animateIn}`:`animated ${animateOut}`}`}>{data[0].title}</h3>
                <div style ={{paddingBottom:'0', marginBottom:'0'}} className={`${inView?`animated ${animateIn}`:`animated ${animateOut}`}`}>{data[0].body}</div>
                
                <div  className={`flex`} >
                    <div className={`flex ${inView?'animated fadeIn':'animated fadeOut'}`}>

                        <img src={p1000} alt="animated gif of 1000 Prinnies dying" className='many-prinny'/>
                    </div>
                    
                </div>
                <div className="many-prinnies">x 1000</div>
                <div className="trigger-container" style={{height:'5px', 
                border:'solid transparent 1px'
                }}>
                                    <div className="trigger-animation" style={{height:'1px', position:'absolute'}}></div>

                </div>
                
            </div>
            
            <div ref={ref2} className={`system-item flex col`}>

                <h3 className={`system-h3 shadow ${inView2?`animated ${animateIn}`:`animated ${animateOut}`}`}>{data[1].title}</h3>
                <div className={`${inView2?`animated ${animateIn}`:`animated ${animateOut}`}`}>{data[1].body}</div>

                
                <div className="flex wrap">
                    
                    <img  src={imgBB2} alt="screenshot of Prinny attacking with knives" className="system-img "/>
                    <img  src={imgBB} alt="screenshot of Prinny attacking while riding a vehicle" className="system-img "/>
                </div>
                
            </div>
            <div ref={ref3} className={`system-item flex col`}>

                    <img src={asagiHappy} alt="Prinny Asagi Happy" className="system-char-img shadow"/>
                    <h3 style={{marginTop:-6}}
                    className={`system-h3 shadow ${inView3?`animated ${animateIn}`:`animated ${animateOut}`}`}>{data[2].title}</h3> 

                
                
                <div className={`${inView3?`animated ${animateIn}`:`animated ${animateOut}`}`}>{data[2].body}</div>
                <div className="flex wrap">
                    <img  src={imgSpecial} alt="Prinny special attack 1" className="system-img "/>
                    <img  src={imgSpecial2} alt="Prinny special attack 2" className="system-img "/>

                </div>
            </div>
            <div ref={ref4} className={`system-item flex col`}>
                <img  src={flonneHappy} alt="Prinny Flonne Happy" className="system-char-img shadow"/>
                <h3 style={{marginTop:0}} className={`system-h3 shadow ${inView4?`animated ${animateIn}`:`animated ${animateOut}`}`}>{data[3].title}</h3>
                <div className={`${inView4?`animated ${animateIn}`:`animated ${animateOut}`}`}>{data[3].body}</div>
                <img  src={imgModes} alt="Prinny baby mode 1000 lives game mode screenshot" className="system-img "/>
            </div>
        </section>
    )
}

