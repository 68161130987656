import React from 'react';
import logo from './logo.svg';
import './App.css';
import './system.css'
import './mq.css'
import { Characters } from './components/characters';
import 'animate.css'
import { Footer } from './components/Footer';
import { Story } from './components/Story';
import { Header } from './components/Header';
import { System } from './components/System';
import { Nav } from './components/Nav';
import { Intro } from './components/Intro';
import { Modal } from './components/Modal';
import { Covers } from './components/Covers';

export const Homepage = () => {
    const [modal, setModal] = React.useState(false)

    const modalContent = (<div className="youtube-container flex" >
      <iframe  className="youtube" src="https://www.youtube.com/embed/ssVvgK_5hDQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>)
  
  
    React.useEffect(()=>
    {
      var maxWidth = document.documentElement.offsetWidth;
  [].forEach.call(
    document.querySelectorAll('*'),
    function(el) {
      if (el.offsetWidth > maxWidth) {
        console.log(el);
      }
    }
  );
    },[])
    return (
  
      <div className="App flex col">
        <Intro/>
        <Modal show={modal} content={modalContent} setModal={setModal} color='rgb(0,0,0, .8)'/>
        <Nav setModal={setModal}/>
        <Header />
        
        <Story/>
        <System/>
        <Characters/>
        <Covers/>
        <Footer/>
      </div>

    )
}
