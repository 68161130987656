import React from 'react'
import pdf1 from '../assets/downloads/reversecover-1.pdf'
import pdf1cover from '../assets/img/reversecover-1.png'

import pdf2 from '../assets/downloads/reversecover-2.pdf'
import pdf2cover from '../assets/img/reversecover-2.png'

export const Covers = () => {
    return (
        <div id="covers" className="flex col" style={{backgroundColor:'rgba(0,0,0,.7)', width:'100%'}}>
            <h1 className="animated pulse infinite" style={{fontSize:'2.5em', width:'60%'}}>ALTERNATIVE COVERSHEETS</h1>
            <div className="flex wrap">
                <div className="flex col alt-cover" >
                    <img src={pdf1cover} alt="" style={{width:"30vw", minWidth:"300px", maxWidth:'500px'}}/>
                    <p style={{color:'white'}}>Alternative Coversheet #1 for Nintendo Switch™</p>
                    <a href={pdf1} download="reversecover-1.pdf"style={{textDecoration:'none'}}><div className="flex top-btn" >Download</div></a>
                </div>
                <div className="flex col alt-cover" >
                    <img src={pdf2cover} alt="" style={{width:"30vw", minWidth:"300px", maxWidth:'500px'}}/>
                    <p style={{color:'white'}}>Alternative Coversheet #2 for Nintendo Switch™</p>
                    <a href={pdf2} download="reversecover-2.pdf"style={{textDecoration:'none'}}><div className="flex top-btn" >Download</div></a>
                </div>
                <div className="flex col">
                    
                </div>
            </div>
            
        </div>
    )
}
