import React from 'react'
import headerBG1 from '../assets/img/header-bg-1-rough.png'
import headerBG2 from '../assets/img/header-bg-2-rough.png'
import bgVideo from '../assets/video/bgloop.mp4'
import storyPage from '../assets/img/story-page.png'
import charBG from '../assets/img/char-bg.png'
import pLogo1 from '../assets/img/page-logo-1.png'
import pLogo2 from '../assets/img/page-logo-2.png'

export const Story = () => {
    
    const [rotateY, setRotateY] = React.useState(0)
    const [posInStory, setPosInStory] = React.useState(0)
    const [showPage, setShowPage] = React.useState('animated fadeOut')
    const [flipText, setFlipText] = React.useState(false)
    const fadeOutBookBuffer = 0
    const pageTurnBuffer = 300

    const convertRangeToDeg = (val, oldMin, oldMax) =>
    {

        console.log("VAL MUST BE BETWEEN RANGE. VAL IS:", val)
        console.log("VAL MUST BE BETWEEN RANGE. range IS:", oldMin, oldMax)
        const range1 = oldMax - oldMin;

        const min2 = 0;
        const max2 = 180;
        const range2 = max2 - min2;

        // const val2 = val*range2/range1 + min2;
        const val2 = ((val - oldMin) / (oldMax - oldMin) * (max2 - min2) + min2)
        if (val2 > 180)
        {
            return 180
        }
        return val2
        // return val / oldMax * max2
    }

    React.useEffect(()=>
    {
        
        
        let lis = window.onscroll = ()=>
        {
            
            let storyComponent = document.getElementById('story')
            if(storyComponent===null)
            {
                return
            }

            let storyDistanceFromTopOfPage = storyComponent.offsetTop
            let storyComponentHeight = storyComponent.scrollHeight
            let bookHeight = document.getElementsByClassName('story-page')[0].scrollHeight
            let scrollPosition = window.pageYOffset


            let scrollPositionInStory = scrollPosition - storyDistanceFromTopOfPage
            let scrollPositionInTurn = scrollPositionInStory - pageTurnBuffer

            let insideStoryScrollRange = [storyDistanceFromTopOfPage, storyComponentHeight - storyDistanceFromTopOfPage]
            let insidePageTurnRange = [insideStoryScrollRange[0] + pageTurnBuffer, insideStoryScrollRange[1] - pageTurnBuffer] 
            
            // console.log("storyDistanceFromTopOfPage", storyDistanceFromTopOfPage)
            // console.log("storyComponentHeight", storyComponentHeight)
            // console.log("scrollPosition is: ", scrollPosition)
            console.log("scrollPositionInStory is: ", scrollPositionInStory)
            setPosInStory(scrollPositionInStory)

            //Check if we are inside Story Component in scrolling

            if (scrollPositionInStory > -1 && scrollPositionInStory < storyComponentHeight)
            {
                console.log('turn page range is: ', insidePageTurnRange[0], insidePageTurnRange[1])
                console.log('position inside turning is: ', scrollPositionInTurn)
                setShowPage('animated fadeIn')


                //Turn page between desired range and set to 0 or 180 as needed
                if(scrollPositionInTurn > pageTurnBuffer &&  scrollPositionInTurn < insidePageTurnRange[1] - pageTurnBuffer)
                {
                    console.log('deg value', convertRangeToDeg(scrollPositionInTurn, pageTurnBuffer, insidePageTurnRange[1] - pageTurnBuffer))
                    setRotateY(convertRangeToDeg(scrollPositionInTurn, pageTurnBuffer, insidePageTurnRange[1] - pageTurnBuffer))
                    // Flip text when in middle of range scroll
                    if(rotateY > 89 )
                    {
                        setFlipText(true)
                    }
                    else
                    {
                        setFlipText(false)
                    }
                }
                else if (scrollPositionInTurn <  insidePageTurnRange[0])
                {
                    setRotateY(0)
                } else {
                    setRotateY(180)
                }
                
                //Fade out the book inside the story component before it overlaps

                if(scrollPosition > storyComponentHeight - fadeOutBookBuffer)
                {
                    setShowPage('animated fadeOut')
                }
            }
            else
            {
                if(showPage!=="hide")
                setShowPage('animated fadeOut')
            }



            // if(scrollPosition > 450 + storyDistanceFromTopOfPage)
            // {
            //     setFlipText(true)
            // }else{
            //     setFlipText(false)
            // }
            
            // if(scrollPosition > storyDistanceFromTopOfPage + storyComponentHeight - bookHeight - fadeOutBookBuffer|| scrollPosition < storyDistanceFromTopOfPage)
            // {
            //     if(showPage=="animated fadeIn")
            //     setShowPage('animated fadeOut')
            //     // setPageScroll(0)
            // }
            // else if(scrollPosition > storyDistanceFromTopOfPage ){
            //     setShowPage('animated fadeIn')
            //     if(scrollPosition < storyComponentHeight - storyDistanceFromTopOfPage)
            //     {
            //         setPageScroll(scrollPosition - storyDistanceFromTopOfPage)
            //     }
                
            // }

        }
    })

    return (
        <div id="story" className="flex col" style={{backgroundColor:'red'}}>
        <div className="col-overlay" style={{backgroundImage:`url(${charBG})`}}/>
            
            <video autoPlay muted loop playsInline id="bgVideo">
                <source src={bgVideo} type="video/mp4"/>
            </video>
            
            <div className={`story-page flex start show-on-desktop-only ${showPage}`}>
            <h1 className="animated pulse infinite" 
            style={{position:'absolute', bottom:`95%`, left:'130px', fontSize:'4em', color:''}}
             >STORY 

             </h1>
            
                <img src={headerBG1} alt="Prinny artword from first game" className="page-left"></img>
                <div className="page-right flex col" style={{transform:`rotateY(-${rotateY}deg)`, backgroundImage:`none`}}>
                <img src={storyPage} alt="Prinny flip book page" className="page-right" style={{position:'absolute', width:'100%'}}></img>
                    <h2 className="page-title" style={{zIndex:1}}>{storyText.title1}</h2>
                    <p className="page-body" style={{zIndex:1}}>
                        {storyText.pg1}
                    </p>
                </div>
                
                <div className="page-right" style={{position:'absolute', left:'50%', zIndex:-1}}>
                <img src={headerBG2} alt="Prinny artword from second game" className="page-right-img"></img>
                </div>
                <div className="page-right flex col backpage" style={{
                    zIndex:flipText?0:-1,
                    transformOrigin:'right',position:'absolute',transform:`rotateY(-${rotateY + 180}deg)`, backgroundImage:`none`}}>
                    <img src={storyPage} alt="Prinny flip book page" className="page-right" style={{position:'absolute', width:'100%'}}></img>
                    <h2 className="page-title" style={{zIndex:0}}>{storyText.title2}</h2>
                    <p className="page-body" style={{zIndex:0}}>
                    {storyText.pg2}
                    </p>
                </div>
                
            </div>

            <div className={` story-page flex show-on-mobile-only ${showPage}`}>
            <h1 className={`animated pulse infinite`}
            style={{position:'absolute', bottom:`100%`, left:'10%', fontSize:'3em', color:'', transform:'translateX(-50%)'}}
            >STORY</h1>
            

                <div className={`page-right flex col ${showPage}`} style={{position:'absolute',transform:`rotateY(-${rotateY}deg)`, backgroundImage:`url(${storyPage})`}}>
                    <h2 className="page-title" >{storyText.title1}</h2>
                    <p className="page-body">
                        {storyText.pg1}
                    </p>
                </div>
                

                <div className="page-right flex col backpage" style={{

                    transformOrigin:'center',position:'absolute',transform:`rotateY(-${rotateY + 180}deg)`, backgroundImage:`url(${storyPage})`}}>
                    <h2 className="page-title" >{storyText.title2}</h2>
                    <p className="page-body">
                    {storyText.pg2}
                    </p>
                </div>
                
            </div>

        </div>
    )
}


const storyText = {
    title1: <><div className="book-title" style={{width:'100%', textAlign:'center', color:'red'}}>
        <img src={pLogo1} alt="Prinny part 1 logo" width="100%"/>
    </div>
    {/* <div className="book-subtitle" style={{textAlign:'center'}}>
        CAN I REALLY BE THE HERO?
    </div> */}
    </>
    ,
    title2: <><div className="book-title" style={{width:'100%', textAlign:'center', color:'red'}}>
    <img src={pLogo2} alt="Prinny part 2 logo" width="95%"/>
</div>
{/* <div className="book-subtitle" style={{textAlign:'center'}}>
    CAN I REALLY BE THE HERO?
</div> */}
</>,
    pg1: `Master Etna demands the ultimate dessert, and it is up to a legion of lowly Prinnies to make it! They’ve got just 10 hours, 1000 lives, and an entire Netherworld to scour for ingredients. It’s do or die, dood!`,
    pg2: `When the mysterious Phantom Thief steals Master Etna’s panties, the Prinny horde must retrieve them...or else! What unspeakable lengths will they go to retrieve Master Etna’s unmentionables!? Also, dive into the bonus story of the OTHER protagonist(?) of the DISGAEA games with Asagi Wars: Vengeance of Asagi!`
}