import React from 'react'
import nisIcon from '../assets/img/nis_w.svg'
import nisaIcon from '../assets/img/nisa.png'
import esrbIcon from '../assets/img/prinny-2.svg'
import esrbIconT from '../assets/img/logo-esrb-t.png'
import switchIcon from '../assets/img/nintendo-switch-logo-red.jpg'


export const Footer = () => {
    const [email, setEmail] = React.useState('')
    return (
        <footer id="footer" className="flex col">
            <div className="sub-box flex col pad">
                <p>Subscribe to get the latest information!</p>

                <div className="flex wrap pad">
                    <a href="https://twitter.com/nisamerica" target="_blank" className="track-link" data-label="Twitter"><i className="fab  fa-twitter social-icon"></i></a>

                    <a href="https://www.facebook.com/NISAmericaInc/" target="_blank" className="track-link" data-label="Facebook"><i className="fab   fa-facebook-f social-icon"></i></a>

                    <a href="https://www.instagram.com/nisamerica/" target="_blank" className="track-link" data-label="Instagram"><i className="fab  fa-instagram social-icon"></i></a>

                    <a href="https://www.youtube.com/user/NISAmerica" target="_blank" className="track-link" data-label="YouTube"><i className="fab  fa-youtube social-icon"></i></a>

                    <a href="https://www.twitch.tv/nisamerica" target="_blank" className="track-link" data-label="Twitch"><i className="fab  fa-twitch social-icon"></i></a>

                    <a href="https://discord.gg/nisa" target="_blank" className="track-link" data-label="Discord"><i className="fab  fa-discord social-icon"></i></a>

                </div>

                
                <div className="mailchimp">
                    {/* <input type="email" placeholder="email address" required style={{width:'300px', height:'32px', borderRadius:'5px', border:'solid white 1px', fontSize:'16px'}}/> */}
                    <div id="mc_embed_signup">

                        <form action="https://nisamerica.us11.list-manage.com/subscribe/post?u=e8fc423eff95099d1ffcc6009&amp;id=349f7d20f9" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>

                            <div id="mc_embed_signup_scroll" className='flex'>

                        

                            <input style={{width:'150px', height:'32px', borderRadius:'5px', border:'solid white 1px', fontSize:'16px'}} type="email" onChange={(e)=>setEmail(e.currentTarget.value)} value={email} name="EMAIL" className="email" id="mce-EMAIL" placeholder="email address" required/>

                            {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}

                            <div 
                            style={{position: 'absolute', left: '-5000px'}} 
                            aria-hidden="true">
                                <input type="text" name="b_e8fc423eff95099d1ffcc6009_349f7d20f9" tabIndex="-1" defaultValue=""/>
                            </div>

                            {/* <div class="clear"> */}
                                <button style={{fontSize:'.8em', width:'120px','height':'32px', borderRadius:'2px', textAlign:'center', fontFamily:'cuckoo'}} type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="top-btn track-link" data-label="Subscribe">
                                Subscribe
                                </button>
                            {/* </div> */}

                            </div>

                        </form>
                    </div>

                </div>
                <div className="flex row wrap">
                    <div className="esrb-box flex col">
                        <img src={esrbIcon} alt="" className="brand-icon" style={{width:'165px'}}/>
                        <div className="esrb-subtitle">
                        Prinny® 1•2:<br/>Exploded and Reloaded
                        </div>
                    </div>
                    <div className="esrb-box flex col">
                        <img src={esrbIconT} alt="" className="brand-icon" style={{width:'165px'}}/>
                        <div className="esrb-subtitle">
                        Prinny®1:<br/> Can I Really Be the Hero? 
                        </div>
                    </div>
                    <div className="esrb-box flex col">
                        <img src={esrbIcon} alt="" className="brand-icon" style={{width:'165px'}}/>
                        <div className="esrb-subtitle">
                        Prinny®2:<br/> Dawn of Operation Panties, Dood!
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom flex col">
                <div className="brands flex wrap">
                    <img src={switchIcon} alt="" className="brand-icon"/>
                    <img src={nisIcon} alt="" className="brand-icon"/>
                    <a href="http://nisamerica.com/" target="_blank"><img src={nisaIcon} alt="" className="brand-icon"/></a> 
                    {/* <img src={esrbIcon} alt="" className="brand-icon" style={{width:'165px'}}/> */}
                </div>
                <p style={{color:'white', fontWeight:'lighter', padding:'0', paddingTop:0,textAlign:'center', fontFamily:'Arial', fontSize:'initial'}}>
                    ©2020 Nippon Ichi Software Inc. ©2020 NIS America, Inc. All rights reserved.
                    <br/>
                    Prinny is a registered trademark of Nippon Ichi Software Inc.
                    <br/>
                    Nintendo Switch is a trademark of Nintendo.
                    <br/>
                    The rating icon is a trademark of the Entertainment Software Association.
                    <br/>
                    All other trademarks are properties of their respective owners.
                </p>
            </div>
        </footer>


    )
}
