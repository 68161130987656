import React from 'react'
import HamburgerMenu from 'react-hamburger-menu'
import track from './track'

export const Nav = (props) => {
    const [topPos, setTopPos] = React.useState(0)
    const [storyPos, setStoryPos] = React.useState(0)
    const [burger, setBurger] = React.useState(false)
    const [burgerClass, setBurgerClass] = React.useState('-110%')

    const scrollTo = (e,pos) =>
    {
        e.currentTarget.blur()
        document.getElementById(pos).scrollIntoView();
    }

    const handleBurgerClick = () =>
    {
        setBurgerClass(burgerClass==='-110%'?'0':'-110%')
        setBurger(!burger)
    }
    return (
        <>
        <div id='nav' className='flex start shadow show-on-desktop-only'>
            <button className="top-btn flex col btn" style={{borderWidth:0, width: 'initial', height:'40px', fontSize:'.8em', fontFamily:'cuckoo', backgroundColor:'white', color: 'black', textShadow:'none'}} onClick={()=>{
                track('Watch the Trailer')
                props.setModal(true)}}> TRAILER</button>
            <button className="top-btn flex col btn" style={{borderWidth:0, width: 'initial', height:'40px', fontSize:'.8em', fontFamily:'cuckoo'}} onClick={(e)=>scrollTo(e,'header')}>TOP</button>
            <button className="top-btn flex col btn" style={{borderWidth:0, width: 'initial', height:'40px', fontSize:'.8em', fontFamily:'cuckoo'}} onClick={(e)=>scrollTo(e,'story')}>STORY</button>
            <button className="top-btn flex col btn" style={{borderWidth:0, width: 'initial', height:'40px', fontSize:'.8em', fontFamily:'cuckoo'}} onClick={(e)=>scrollTo(e,'system')}>SYSTEM</button>
            <button className="top-btn flex col btn" style={{borderWidth:0, width: 'initial', height:'40px', fontSize:'.8em', fontFamily:'cuckoo'}} onClick={(e)=>scrollTo(e,'characters')}>CHARACTERS</button>
            <button className="top-btn flex col btn" style={{borderWidth:0, width: 'initial', height:'40px', fontSize:'.8em', fontFamily:'cuckoo', position:'relative'}} onClick={(e)=>scrollTo(e,'covers')}><span className="animated tada infinite" style={{transform: 'rotate(-20deg)', position:'absolute', left:0, top:-10, fontSize:'1.4em'}}>new!</span>ALTERNATIVE COVERSHEETS</button>
        </div>
        <div className="show-on-mobile-only">
            <div className="flex" style={{position:'fixed',right:'5px', top:'5px', zIndex:5, backgroundColor:'#ffd704',padding:'16px', borderRadius:'5px'}}>
                    <HamburgerMenu
                    isOpen={burger}
                    menuClicked={()=>handleBurgerClick()}
                    width={18}
                    height={20}
                    strokeWidth={3}
                    rotate={0}
                    color='black'

                    animationDuration={0.5}
                />
                </div>
        </div>
        <div id='nav' className='flex start col shadow show-on-mobile-only' style={{transform:`translateY(${burgerClass})`, padding:'16px', transition:'.3s cubic-bezier(0.6, -0.28, 0.74, 0.05)'}}>
            
            
            <div className={`mobile-nav-container flex col`}>
                <button className="top-btn flex col btn" style={{borderWidth:0, width: 'initial', height:'32px', fontSize:'.8em', fontFamily:'cuckoo', backgroundColor:'white', color: 'black', textShadow:'none'}} onClick={()=>{
                    handleBurgerClick()
                    track('Watch the Trailer')
                    props.setModal(true)}}> TRAILER</button>
                <button className="top-btn flex col btn" style={{borderWidth:0, width: 'initial', height:'32px', fontSize:'.8em', fontFamily:'cuckoo'}} onClick={(e)=>{
                    handleBurgerClick()
                    scrollTo(e,'header')}}>TOP</button>
                <button className="top-btn flex col btn" style={{borderWidth:0, width: 'initial', height:'32px', fontSize:'.8em', fontFamily:'cuckoo'}} onClick={(e)=>{
                    handleBurgerClick()
                    scrollTo(e,'story')}}>STORY</button>
                <button className="top-btn flex col btn" style={{borderWidth:0, width: 'initial', height:'32px', fontSize:'.8em', fontFamily:'cuckoo'}} onClick={(e)=>{
                    handleBurgerClick()
                    scrollTo(e,'system')}}>SYSTEM</button>
                <button className="top-btn flex col btn" style={{borderWidth:0, width: 'initial', height:'32px', fontSize:'.8em', fontFamily:'cuckoo'}} onClick={(e)=>{
                    handleBurgerClick()
                    scrollTo(e,'characters')}}>CHARACTERS</button>
                    <button className="top-btn flex col btn" style={{borderWidth:0, width: 'initial', height:'48px', fontSize:'.8em', fontFamily:'cuckoo', position:"relative"}} onClick={(e)=>{
                    handleBurgerClick()
                    scrollTo(e,'covers')}}><span className="animated tada infinite" style={{transform: 'rotate(-20deg)', position:'absolute', left:0, top:-10, fontSize:'1.4em'}}>new!</span>ALTERNATIVE<br/>COVERSHEETS</button>
            </div>
        </div>
        </>
    )
}
