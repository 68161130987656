import React from 'react'

export const Modal = (props) => {
    if(props.show)
    {
        return (
        <div id="modal" onKeyDown={(k)=>console.log(k)} className="flex col animated zoomIn" onClick={()=>props.setModal(false)} style={{width:'100%', height:'100vh', position:'fixed', top:0,backgroundColor:props.color, zIndex:20}}>
            <div className={`flex col`}>
                <button className='btn' style={{margin:'8px',color:'white',position:'absolute', top:0,right:0, padding:'16px', fontSize:'2em', backgroundColor:'transparent'}}>X</button>
              {props.content}  
            </div>
            
        </div>
    )
    }
    else {
        return null
    }
    
}
