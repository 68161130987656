import React from 'react'

export const MotionChar = (props) => {
    const [flip, setFlip] = React.useState(false)
    const [currentLen, setCurrentLen] = React.useState(0)
    const times = props.times

React.useEffect(()=>
{   
    // dont loop if loop false
    if(props.loop === false && currentLen === times.length - 1)
    {
        return
    }
    let len = props.times[0]

    if(currentLen === times.length - 1)
    {
        len = props.times[0]
    } else {
        len = props.times[currentLen]
    }

    let tim = setTimeout(() => {

        if(currentLen === times.length -1)
        {
            setFlip(!flip)
            setCurrentLen(0)
        }
        else {
            setFlip(!flip)
            setCurrentLen(currentLen + 1)
        }
        
    }, [times[currentLen]]);

    return () => clearTimeout(tim)
},[currentLen])

    
    return (
       
            <img src={props.images[flip?1:0]} alt="Prinny animated with javascript" className={props.className} style={props.style} height={props.height?props.height:'initial'} width={props.width?props.width:'150px'}/>
      
    )
}
