import React from 'react'
import img1 from '../assets/img/p-intro-1.png'
import img2 from '../assets/img/p-intro-2.png'
import { MotionChar } from './MotionChar'

export const Intro = () => {
    return (
        <div style={{width:'100%', maxWidth:'100%', height:'100vh', position:'fixed', zIndex:4, backgroundColor:'#ffd704', top:0, left:0}} className="flex animated slideOutUp delay-1s ">
            <MotionChar loop={false} images={[img1,img2]} times={[500,1500]} height="150px"/>
        </div>
    )
}
