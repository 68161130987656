import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'

const analytics = Analytics({
    app: 'awesome-app',
    plugins: [

      googleAnalytics({
          trackingId: 'UA-5550116-14'
      })
    ]
  })
  analytics.page()

const track = (label) => {
    analytics.track("generate_lead",{
      category: "",
      label: label

    })
}

export default track;