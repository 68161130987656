import React from "react";
import logo from "../assets/img/main-logo-4.png";
import logo2 from "../assets/img/main-logo-4-2.png";
import asagi1 from "../assets/img/asagi-eyes-open.png";
import pattern from "../assets/img/prinny-pattern.png";
import esrb1 from "../assets/img/logo-esrb-t.png";
import esrb2 from "../assets/img/prinny-2.svg";
import track from "./track";
import promo from "../assets/img/mrd-cross-promo.png";

// import html from '../index2.html'

import asagi2 from "../assets/img/asagi-eyes-closed.png";
import { MotionChar } from "./MotionChar";
import { Modal } from "./Modal";

export const Header = (props) => {
  const [pageScroll, setPageScroll] = React.useState(0);
  const [esrb, setEsrb] = React.useState(null);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    let tim = setTimeout(() => {
      setEsrb(
        <div
          className="flex row start animated fadeOut delay-5s"
          style={{ position: "absolute", top: 0, left: 0 }}
        >
          <div className="flex col">
            <img src={esrb2} height={"68px"} alt="" className="esrb" />
          </div>
        </div>
      );
    }, 1500);

    return () => clearTimeout(tim);
  }, []);
  return (
    <header
      id="header"
      className="flex col"
      // style={{backgroundColor:'rgba(47, 0, 175, 0.47)'}}
    >
      {esrb}

      {/* <div className="header-bg" style={{backgroundColor:`black`,width:'100%', height:'100%', position:'absolute', backgroundSize:'32px', opacity:.3}}></div> */}

      {/* <div className="header-bg" style={{backgroundImage:`url(${pattern})`,width:'100%', height:'100%', position:'absolute', backgroundSize:'32px', opacity:.5}}></div> */}

      {/* <img src={logo} alt="" className="main-logo shadow" width="700px" style ={{marginTop:'64px'}}/> */}
      <MotionChar
        images={[logo, logo2]}
        className="header-logo"
        times={[4000, 100, 3000, 100, 4000, 100, 100, 200]}
      />
      {/* <MotionChar images={[asagi1,asagi2]} times={[2000, 100]}/> */}

      {/* <div className="top-btns flex-mobile"> */}
      {/* <Link to="/product"><button className="margin top-btn flex col btn" style={{borderWidth:0}}>PRE-ORDER</button></Link> */}
      <a
        href={process.env.PUBLIC_URL + "/products.html"}
        style={{ textDecoration: "none" }}
      >
        <button
          className="margin top-btn flex col btn"
          style={{ borderWidth: 0 }}
        >
          BUY NOW
        </button>
      </a>
      {/* <a
        href={
          "https://store.nisamerica.com/red-moon-day-charity-fundraiser-2020-t-shirt"
        }
        target="_blank"
        style={{ textDecoration: "none" }}
        className="flex"
      >
        <button
          className="margin top-btn flex col btn promo"
          style={{
            borderWidth: 0,
            width: "initial",
            height: "initial",
            padding: "8px",
          }}
          onClick={() => track("Red Moon Charity")}
        >
          Get your Red Moon Day 2020 T-Shirt Here
          <br />{" "}
          <span style={{ fontSize: ".8em", fontFamily: "arial" }}>
            all proceeds go to Red Cross COVID-19 relief
          </span>
        </button>
      </a> */}

      {/* </div> */}
      <div className="logo-container">
        <h1
          className="header-h1 animated pulse infinite top-text"
          style={{ textAlign: "center", marginTop: "32px" }}
        >
          Coming to Nintendo Switch™
          <br />
          <div className="flex row wrap top-text-sub">
            <span>10.13.2020 (NA)</span>&ensp;<span>10.16.2020 (EU)</span>&ensp;
            <span>10.23.2020 (ANZ)</span>
          </div>
        </h1>
        <div className="flex row promo-mrd">
          <a href="https://nisamerica.com/mad-rat-dead/" target="_blank">
            <img src={promo} alt="" className="promo-mrd" />
          </a>
        </div>
      </div>
    </header>
  );
};
