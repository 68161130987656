import React from 'react'
import prinny from '../assets/img/prinny-char.png'
import asagiClosed from '../assets/img/asagi-eyes-closed.png'
import asagi from '../assets/img/asagi.png'
import etna from '../assets/img/etna.png'
import flonne from '../assets/img/flonne.png'
import etnaHead from '../assets/img/etna-head.png'
import palette from '../palette'
import pattern from '../assets/img/prinny-pattern.png'

export const Characters = () => {
    const [characterBoxes, setCharacterBoxes] = React.useState([])
    const [bigChar, setBigChar] = React.useState([])
    const [selectedChar, setSelectedChar] = React.useState(0)

    const makeItems = (arr) =>
    {
        setCharacterBoxes(arr.map((e,i)=>
        {
            return (
                <button key={i} className="character-box flex col btn" style={{border:i===selectedChar?"solid white 2px":"solid white 0px"}} onClick={(e)=>{
                    
                    setSelectedChar(i)
                    e.currentTarget.blur()

                    }}>
                    {e.name}
                </button>
            )
        }))

        setBigChar(
            <div id="big-char" className="flex-mobile-med" >
                <div className="description-box flex col">                    

                    <h2 className="animated fadeInUp slow">{characterArray[selectedChar].name}</h2>
                    <p className="pad animated fadeIn">{characterArray[selectedChar].description}</p>

                    
                </div>
                <div className="big-char-container" style={{backgroundImage:`url(${pattern})`}}>
                {/* {<button className='flex show-on-mobile-only next-btn shadow' onClick={()=>setSelectedChar(selectedChar===3?0:selectedChar+1)}><span style={{color:'white'}} className="material-icons shadow">
forward
</span></button>} */}
                   <img src={characterArray[selectedChar].img} className={`big-char animated slideInRight shadow`} alt={characterArray[selectedChar].name} />
                   
                   
                </div>
                
                
            </div>

            
        )
    }

    React.useEffect(()=>
    {
        var maxWidth = document.documentElement.offsetWidth;
[].forEach.call(
  document.querySelectorAll('*'),
  function(el) {
    if (el.offsetWidth > maxWidth) {
      console.log(el);
    }
  }
);
        makeItems(characterArray)
    },[])

    React.useEffect(()=>
    {
        makeItems(characterArray)
    },[selectedChar])

    
    return (
        <div id="characters" className="flex col" style={{backgroundColor:'#1c1d45'}} key={selectedChar}>
            <h1 className='animated pulse infinite'>CHARACTERS</h1>
            {bigChar}
            <div className="flex char-boxes">
                {characterBoxes}
            </div>
            
        </div>
    )
}



const characterArray = [
    {
        name: 'Prinny',
        headImg: prinny,
        img: prinny,
        description: `The hapless yet devoted servants of Beauty Queen Etna, and the lowest class of life forms in the Netherworld. They (reluctantly) carry out Etna’s orders, and often sacrifice themselves to do it!`
    },
    {
        name: 'Etna',
        headImg: etnaHead,
        img: etna,
        description: `The feared and beloved “beauty queen” of the Netherworld. When her belongings suddenly begin to go missing, Etna will stop at nothing to make her Prinny minions get them back, even if it means threatening them with unspeakable forms of punishment.`
    },
    {
        name: 'Flonne',
        headImg: '',
        img: flonne,
        description: `A fallen angel who is very supportive of Etna and the Prinnies. While not directly aiding the Prinnies in their efforts to appease Etna, Flonne is always a chipper and encouraging presence.`
    },
    {
        name: 'Asagi',
        headImg: asagi,
        img: asagi,
        img2: asagiClosed,
        description: `“That’s right, the REAL hero of this series is here, dood! I’ll stop at nothing to reclaim my rightful place among the headlines and title cards as the ONE TRUE ASAGI. Mark my words, this isn’t the last you’ve seen of me, dood!”`
    },

]
