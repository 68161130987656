import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import './system.css'
import './mq.css'
import { Characters } from './components/characters';
import 'animate.css'
import { Footer } from './components/Footer';
import { Story } from './components/Story';
import { Header } from './components/Header';
import { System } from './components/System';
import { Nav } from './components/Nav';
import { Intro } from './components/Intro';
import { Modal } from './components/Modal';
import { Homepage } from './Homepage';
import { ProductPage } from './ProductPage';


function App() {
  const router = false

  if(router)
  {
    return (

    <main>
            <Switch>
                <Route path="/" component={Homepage} exact />
                <Route path="/product" component={ProductPage} />
                <Route path="/shop" component={Nav} />
                <Route component={Error} />
            </Switch>
        </main>
  );
  }
  else
  {
    return <Homepage/>
  }
  
}

export default App;


const Error = () =>
{
  return (
    <div>error</div>
  )
}